import { render, staticRenderFns } from "./SubmissionWorkflowStepper.vue?vue&type=template&id=0906a332&scoped=true&"
import script from "./SubmissionWorkflowStepper.vue?vue&type=script&lang=js&"
export * from "./SubmissionWorkflowStepper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0906a332",
  null
  
)

export default component.exports